.minecraft-hero {
  height: 70%;
  min-height: 400px;
  background-image: url('https://hentaminecdn.blob.core.windows.net/content/promo-server-night.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;;
}

.minecraft-hero h1,
.minecraft-hero p { 
  color: white;
  max-width: 600px;
} 

.minecraft-hero h1 {    
  font-weight: 700;
}

.minecraft-skin-promo {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-gap: 1rem;
}

.minecraft-skin-promo a {
  text-decoration: none;
  color: black;
  transition: .3s;
}

.minecraft-skin-promo a:hover,
.minecraft-skin-promo a:active {  
  color: #aaa;  
}

.minecraft-skin-promo a:hover img,
.minecraft-skin-promo a:active img {
  transform: scale(1.02);
}

.minecraft-skin-promo img {
  object-fit: cover;
  width: 100%;
  transition: .3s;
  border-radius: 1rem;
}

@media (min-width: 600px) {
  .minecraft-skin-promo {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 900px) {
  .minecraft-skin-promo {    
    grid-template-columns: 1fr 1fr 1fr 1fr;
  } 
}

@media (min-width: 1200px) 
{
  .minecraft-hero {
    height: 70%;
  }
}