ul {  
  list-style: none;
  margin-top: 0;
	padding-left: 0;
}

li {
  padding:1em;
  border-bottom: 1px solid #efefef;
  display: flex;
}

ul.selectable li {
  transition: 0.1s;
  cursor: pointer;
}

ul.selectable li:hover {
  background: rgba(0, 0, 0, 0.05);
}