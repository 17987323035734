.contact-grid {
  display: grid;
  min-height: 80vh;
  align-items: center;
}

.contact-grid-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;  
}

@media(min-width: 900px) {
  .contact-grid {
    grid-template-columns: 1fr 1fr;    
  }

  .contact-grid-block {    
    align-items: center;
    text-align: center;
    padding: 0 2rem;
  }
}