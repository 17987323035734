html,
body, 
#root {
  min-height: 100%;
  width: 100%;
}

.section {
  padding: 1rem 0;
}

h1 a,
p a,
.hyperlink {
  text-decoration: none;
  color: #4379f5;
  border-bottom: 2px solid #4379f5;
}

ul {
  margin: 0;
}

dl {
  margin-top: 0;
}

dt {
  font-weight: bold;  
}

dd {
  margin: 0 0 .5rem 0;  
}

.title-1 {
  margin-bottom: .5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.white-bg {
  background-color: white;
}

.gray-bg {
  background-color: #eff0f4;
}

.lavendar {
  background-color: #ba93df;  
}

.error-text {
  color: #c40233;
}

.link-button {
  /* align-self: flex-start;   */
  padding: 1rem;
  font-weight: 500;
  color: #222;  
  text-decoration: none;
  transition: .3s;
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.link-button.wide {
  padding: 1rem 2rem;
}

.link-button:active {
  box-shadow: none;  
}

.link-button.primary {
  background-color: #4379f5;
  color: white;
}

/* Breadcrumb */

.breadcrumb {
  font-weight: 500;
}

/* Content Grid */

.content-grid {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.content-grid-block {
  grid-area: content-block;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center; 
}

.content-grid-media {
  grid-area: content-media;
  width: 100%;
}

/* .audio-promo .content-grid-media {
  background-color: #222;
} */

@media (min-width: 900px) 
{
  .content-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'content-block content-media';
  }

  .content-grid-block {
    justify-content: center;
  }
  

  .content-grid.right {    
    grid-template-areas: 'content-media content-block ';
  }
}

/* Card */

.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: white;
}

.card-image {
  object-fit: cover;
  width: 100%;
}

.card-content {
  padding: 1rem;
}

.card-content p {
  font-size: 14px;
  color: #585e5e;
}

/* Forms & Inputs */

input,
textarea,
select {
  width: 100%;
  box-sizing: border-box;
  border:none;
  border-bottom: 1px solid #777;
  /* border: none; */
  padding: .5em;
  margin-bottom: 1rem;
  font: inherit;
  transition: 0.25s;
  outline: 0;

  
}

input:focus,
textarea:focus,
select:focus {
  border: none;  
  outline-width: 0;
  border-bottom: 2px solid #222;  
}

textarea {
  min-height: 6rem;
  resize: none;
}

@media (min-width: 900px) {
  textarea {
    min-height: 10rem;
  }
}

.field {
  margin-bottom: 1rem;
}

/* Animations */

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

/* Tablet Portrait */
@media (min-width: 600px) 
{
  .section {
    padding: 2rem 0;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) 
{
  html,
  body, 
  #root {
    height: 100%;
    width: 100%;
  }
}

/* Desktop */
@media (min-width: 1200px) 
{
}