html { 
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  /* transition: 1s;   */
}

h1, 
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;  
  /* font-weight: 300; */
}

.section {
  margin: 0;
  padding: 0;
}