.audio-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.breadcrumb {
  font-size: 16px;
}

@media (min-width: 600px) {
  .breadcrumb {
    font-size: 20px;
  }
}

@media (min-width: 900px) {
  .audio-grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 3fr 2fr;
  }

}

@media (min-width: 1200px) {
  
}